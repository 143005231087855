<template>
    <div class="pinned position-fixed top-0">
    <!-- <div class="pinned" :class="props.pinnedClick ? 'position-absolute' : 'position-fixed top-0'"> -->
        <button class="btn btn-link text-primary" @click.stop="(e) => handleClick(e)">
            <i v-if="!row._pinned" class="bi bi-pin" ></i>
            <i v-if="row._pinned" class="bi bi-pin-fill" ></i>
        </button>
    </div>
    
</template>
<script setup>
const props = defineProps(['row', 'column', 'pinnedClick']);

function handleClick(pEvent) {
    if (props.column) {
        props.column.cellRendererParams.pinnedClick(props.row, pEvent);
    } else if (props.pinnedClick) {
        props.pinnedClick(props.row, pEvent);
    }
}

</script>
<style scoped>
    .pinned{
        right:0px;
        /* top:0px; */
    }
    .pinned:hover{
         -webkit-text-stroke: 1px;
    }
    .o365-body-row .pinned{
        display:none;
    }
    .o365-body-row.row-hover .pinned{
        display:block;
    }
    .o365-header-row [o365-field='o365_pinned']{
        display:none;
    }

</style>